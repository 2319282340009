:root {
	--dark-bg-default: #18160c;
	--dark-bg-paper: #2b2703;
}
body {
	overflow: hidden;
}

.container {
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}
.flex-row {
	flex: 1;
	display: flex;
	flex-direction: row;
}
.header {
	height: 4em;
}
.sidebar {
	width: 240px;
}
.body {
	flex: 1 1;
	overflow-y: auto;
	height: calc(100vh - 4em);
	display: flex;
	flex-direction: row;
	justify-content: center;
}
.body > div {
	max-width: 900px;
}
.body > div > div:first-child {
	padding-top: 3em;
}
.body > div > div:last-child {
	padding-bottom: 5em;
}

#root button {
	color: black;
	background-color: #fce082;
	border: 0;
	padding: 0.3em 0.7em;
	border-radius: 3px;
}
#root button:hover {
	background-color: #ffd33d;
	cursor: pointer;
}

.MuiFormGroup-root > div + * {
	margin-top: 2em;
}
